import { useState, useRef, useEffect } from "react";
import { Modal, Box, Button, Typography, useTheme } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const DigitalSignature = ({ onSave, initialSignature }: { onSave: (signature: string | null) => void; initialSignature?: string | null }) => {
    const [open, setOpen] = useState(false);
    const [savedSignature, setSavedSignature] = useState<string | null>(initialSignature || null);
    const [hasDrawn, setHasDrawn] = useState(false);
    const [canvasSize, setCanvasSize] = useState({ width: 750, height: 300 });
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const updateSize = () => {
            const newWidth = window.innerWidth < 576 ? 300 : 750; // Adjust width for phones
            setCanvasSize({ width: newWidth, height: 300 });
        };

        updateSize(); // Set size on mount
        window.addEventListener("resize", updateSize); // Update on resize

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    useEffect(() => {
        if (open) {
            if (sigCanvas.current) {
                sigCanvas.current.clear();
                sigCanvas.current.fromDataURL(savedSignature!);
            } else {
                setTimeout(() => {
                    if (sigCanvas.current) {
                        sigCanvas.current.clear();
                        sigCanvas.current.fromDataURL(savedSignature!);
                    }
                }, 300)
            }
        }
    }, [open])

    useEffect(() => {
        if (sigCanvas.current) {
            const canvas = sigCanvas.current.getCanvas();
            if (!canvas) return;

            const handleStartDrawing = () => setHasDrawn(true);

            canvas.addEventListener("mousedown", handleStartDrawing);
            canvas.addEventListener("touchstart", handleStartDrawing);

            return () => {
                canvas.removeEventListener("mousedown", handleStartDrawing);
                canvas.removeEventListener("touchstart", handleStartDrawing);
            };
        }
    }, [open]);

    const handleClear = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
        setSavedSignature(null);
        setHasDrawn(false);
        onSave(null);
    };

    const handleSave = () => {
        if (sigCanvas.current) {
            const signatureData = sigCanvas.current.toDataURL("image/png");
            setSavedSignature(signatureData);
            onSave(signatureData);
            setOpen(false);
        }
    };

    return (
        <>
            {/* Show the correct button based on whether a signature exists */}
            <Button variant="contained" onClick={() => setOpen(true)}>
                {savedSignature ? "Nënshkrimi Digjital" : "Shto Nënshkrim Digjital"}
            </Button>

            {/* Full-Screen Modal for Signature Pad */}
            <Modal open={open} onClose={() => setOpen(false)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ bgcolor: "white", borderRadius: "15px", py: "1rem", px: "2rem", width: "50%", height: "auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
                , '@media screen and (max-width: 576px)': {width: '100%', height: '100vh', px: "1rem"}}}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Nënshkruaj Këtu</Typography>

                    {/* Signature Canvas */}
                    <SignatureCanvas
                        ref={(ref) => (sigCanvas.current = ref)}
                        penColor="black"
                        canvasProps={{
                            width: canvasSize.width,
                            height: canvasSize.height,
                            className: "sigCanvas",
                            style: { border: "1px solid black", maxWidth: "100%", height: "auto" },
                        }}
                    />

                    <Box sx={{ width: "100%", borderBottom: "2px solid #cecece", mt: "2rem", mb: "0.5rem" }} />

                    {/* Buttons */}
                    <Box sx={{ mt: 2, width: "100%", display: "flex", gap: "10px", justifyContent: "space-between",
                        '@media screen and (max-width: 576px)': {flexDirection: 'column'}}}>
                        <Box>
                            <Button variant="contained" sx={{'@media screen and (max-width: 576px)': {width: '100%'}}} onClick={handleSave}>Ruaj Nënshkrimin</Button>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: theme.palette.grey[400], "&:hover": { backgroundColor: theme.palette.grey[600] }, ml: "0.7rem",
                                    '@media screen and (max-width: 576px)': {width: '100%', ml: "0", mt: "0.7rem"}}}
                                onClick={handleClear}
                            >
                                Fshije
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: theme.palette.grey[500], "&:hover": { backgroundColor: theme.palette.grey[600] },
                                    '@media screen and (max-width: 576px)': {width: '100%', mt: "0.2rem"}}}
                                onClick={() => setOpen(false)}
                            >
                                Anulo
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default DigitalSignature;







